<template>
  <div class="containerx">
    <div class="search-box">
      <div style="margin-left: 20px;"></div>
      设备名称：<div style="width: 15rem; margin-right: 10px;"><el-input :clearable="true" v-model="queryForm.name"
          placeholder="请输入设备名称"></el-input></div>
      设备编号：<div style="width: 15rem;margin-right: 10px;"><el-input :clearable="true" v-model="queryForm.equipmentSn"
          placeholder="请输入设备编号"></el-input></div>
      <el-button @click="searchEquipment" type="primary">查询</el-button>
    </div>
    <div class="table-box">
      <el-table border :stripe="true" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
      style="width: 98%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="name" label="设备名称" >
        </el-table-column>
        <el-table-column prop="serialNumber" label="设备编号"  show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="monitorSerialNumber" label="物联编号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="state" label="设备状态" >
          <template slot-scope="scope">
            <el-tag :type="scope.row.state === 0 ? 'success' : scope.row.state === 1 ? 'warning' : 'danger'"
              disable-transitions>{{ scope.row.state === 0 ? '在线' : scope.row.state === 1 ? '离线' : '异常' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="extinguishing" label="灭火装置" >
          <template slot-scope="scope">
            <el-switch @change="switchChange(scope.row)" v-model="scope.row.extinguishing" active-color="#13ce66"
              inactive-color="#ff4949" :active-value="1" :inactive-value="0" active-text="启动" inactive-text="关闭">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button @click="addressShow(scope.row.id)">地理位置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page-box">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page" :page-size="queryForm.size" layout="prev, pager, next, jumper"
        :total="pageTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'index1',
  props: {
    // 项目id
    projectId: {
      type: Number,
      default: null
    },
    projects: {
      type: Number,
      default: null
    },
    // 层级id
    levelId: {
      type: Number,
      default: null
    }
  },
  components: {
  },
  data() {
    return {
      loading: false,
      queryForm: {
        page: 0,
        size: 8,
        type: 0,
        levelId: null,
        projectId: 1,
        name: null,
        equipmentSn: null
      },
      pageTotal: 0,
      tableData: [],
      // 设备id
      facilityId: 1,

    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //全选/不选
    selectAll(res) {

    },
    addressShow(id) {
      this.$router.push("/dt?id=" + id);
    },
    changeEquipmentId(res, index) {
    },
    switchChange(item) {
      var text = item.extinguishing === 1 ? '确认是否开启？' : '确认是否关闭？'
      this.$confirm(text)
        .then(_ => {
          this.$newGet('/warning/record/update/environment/exting/' + item.id + '/' + item.extinguishing).then(
            res => {
              if (res.code === 2000) {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
              } else {
                item.extinguishing = item.extinguishing === 1 ? 0 : 1
                this.$message({
                  message: res.message,
                  type: 'error'
                })
              }
            }
          )
        })
        .catch(_ => {
          item.extinguishing = item.extinguishing === 1 ? 0 : 1
          this.$message({
            message: '取消操作',
            type: 'error'
          })
        })
  },

  // 设备列表
  getFacility() {
    this.loading = true
    this.$newPost('/equipment/page', this.queryForm).then(res => {
      this.loading = false
      this.tableData = res.data.records
      this.pageTotal = res.data.total
      for (var i = 0; i < this.tableData.length; i++) {
        this.$set(this.tableData[i], 'checked', false)
      }

      this.facility_total = res.data.total
      if (res.data.records.length > 0) {
        this.facilityId = res.data.records[0].id
      }

    })
  },
  handleCurrentChange(e) {
    this.queryForm.page = e
    this.getFacility()
  },
  handleSizeChange(e) {
    this.queryForm.pageSize = e
    this.getFacility()
  },
  inputSn() {
    this.getFacility()
  },
  clearSn() {
    this.equipmentSn = null
    this.getFacility()
  },
  searchEquipment() {
    this.equipmentSn = null
    this.getFacility()
  }
},
mounted() {
  this.getFacility()
},
}
</script>

<style lang="scss" scoped>
.containerx {
  width: 100%;

  .search-box {
    display: flex;
    width: 100%;
    min-height: 8rem;
    align-items: center;
  }

  .table-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .page-box {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

}
</style>
